<script>
import axios from "axios";
export default {
    data() {
        return {
            items: null,
        }
    },
    created() {
        this.getCars();
    },
    methods: {
        async getCars() {
            try {
                const response = await axios.get("https://carwebsite-backend-production.up.railway.app/");
                this.items = response.data;

                // Check if the response contains the specific error code
                if (this.items && this.items.errno === -104 && this.items.code === "ECONNRESET") {
                    this.refreshOnError();
                }
            } catch (err) {
                console.log(err);
            }
        },
        async refreshOnError() {
            window.location.reload();
        },
        getTopThreeItems() {
            // Sort the items array by ID in descending order
            const sortedItems = this.items.sort((a, b) => b.ID - a.ID);
            // Return the first three items
            return sortedItems.slice(0, 3);
        },
        formatPrice(price) {
            // Format the price as currency
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'GBP',
            }).format(price);
        },
        async refreshOnError() {
            window.location.reload();
        },
        formatMileage(mileage) {
            // Format the mileage
            return mileage.toLocaleString('en-US');
        }
    }
};
</script>
<template>
    <main>
        <div class="album py-5">
            <div class="container container-with-bg text-center">

                <div class="row">
                    <div class="col-md-6 offset-md-6 text-middle-right">
                        <h1 style="text-shadow: 0 0 10px rgba(0, 0, 0, 1); color: #ebebd3;">Back on the road</h1>
                    </div>
                </div>
            </div>
            <br>
            <div class="container">
                <h1 style="text-align: center;">Recenly Added cars</h1>
                <hr>
                <div v-if="items">
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        <div class="col" v-for="item in getTopThreeItems()" :key="item.ID">
                            <router-link class="link" :to="{ name: 'carVue', params: { id: item.ID } }">
                                <div class="crd card shadow-sm" style="height: 100%;">

                                    <svg class="bd-placeholder-img card-img-top" width="100%" height="225"
                                        xmlns="http://www.w3.org/2000/svg" role="img"
                                        aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice"
                                        focusable="false">
                                        <image :href="item.Image.split(',')[0].trim()" width="100%" height="100%" />
                                    </svg>
                                    <div class="card-body">
                                        <p class="card-text">
                                            <strong>Value:</strong> {{ formatPrice(item.Value) }}<br>
                                            <strong>Brand:</strong> {{ item.Name }}<br>
                                            <strong>Model:</strong> {{ item.Model }}<br>
                                            <strong>Mileage:</strong> {{ formatMileage(item.Mileage) }}<br>
                                            <strong>Hpi Status:</strong> {{ item.CatStat }}
                                        </p>
                                        <div class="d-flex justify-content-between align-items-center">
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <br>
                    <div class="col d-flex justify-content-center">

                                <a class="view-more alink" href="/cars"><div class="viewMore">View More</div></a>
                    </div>
                </div>
                <div v-else class="d-flex justify-content-center align-items-center" style="height: 300px;">
                    <div class="spinner-border" role="status" style="width: 5rem; height: 5rem;">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<style>
.viewMore{
    border: 1px solid black;
  width: 75%; 
  padding: 10px;
  margin-left: 10px;
  margin-top: 10px;
  opacity: 0.5;
  transition: 0.3s;
  border-radius: 40px;
  font-size: large;
  color: rgb(0, 0, 0);
}


.viewMore:hover{
    background-color: rgb(99, 99, 99);

}

</style>