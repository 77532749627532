<template>
<nav class="navbar navbar-expand-lg navbar-transparent bg-custom" style="border-bottom: 0.2px solid #cfcfcf;">
  
    <div class="container-fluid justify-content-center">
      <a class="navbar-brand" href="/"><img src="../assets/weblogo.png" class="img-fluid" style="width: 250px;"></a>
      <button class="navbar-toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class=" nav-head  navbar-collapse justify-content" id="navbarSupportedContent" style="margin-right:0  ;">
        <ul class=" navbar-nav mb-2 mb-lg-0">
          <li class="nav-item navItem">
            <a class="nav-link fw-bold" aria-current="page" href="/">Home</a>
          </li>
          <li class="nav-item navItem">
            <a class="nav-link fw-bold" aria-current="page" href="#">PX/Sell My Car(Coming soon)</a>
          </li>
          <li class="nav-item navItem">
            <a class="nav-link fw-bold" aria-current="page" href="/contact">Contact</a>
          </li>
          <li class="nav-item navItem">
            <a class="nav-link fw-bold" aria-current="page" href="/findus">Find Us!</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<style>
.navbar-nav .nav-link:hover {
  color: rgb(145, 145, 145) !important;
}
.nav-link {
  color: rgb(191, 191, 191) !important;
  font-size: 1.25em !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif!important;
}
.bg-custom {
  background-image: url('https://images.unsplash.com/photo-1471958680802-1345a694ba6d?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.navbar-toggler-icon {
    background-image: url('../assets/hamburger-button-computer-icons-menu-bar-line-8c68d41d5c8222dbe1ec162bdbcf7462.png');
    filter: invert(100%);
    /* Optionally, use your custom icon */
}
/* Additional custom styling */


.navItem {
  margin-right: 20px; /* Add margin to create space between links */

  
}

@media screen and (max-width: 400px) {
  .navItem {
  margin-right: 0px; /* Add margin to create space between links */
}
  
}



</style>



