<template>
  <div>
    <carList />
  </div>
</template>
<script>
import carList from '../components/carList.vue'
export default {
  name: 'index',
  components: {
    carList
  }
}
</script>