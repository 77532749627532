<template>
<div class="container">



  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">

    <a href="/" style="margin-top: 10px;" class="col-md-4 mb-0 text-muted align-items-left justify-content-left mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none container">
      <img src="../assets/weblogo.png" class="img-fluid"
            style="width: 250px;">
            <p>AW TRADE CENTRE 2024 </p>
    </a>

    <ul class="nav col-xl-8 col-md-4 col-sm-12  footNav">
      <li class="nav-item foot-item footItem"><a href="/contact" class="nav-link-1 px-2 text-body-muted" >Contact</a></li>
      <li class="nav-item foot-item footItem"><a href="/about" class="nav-link-1 px-2 text-body-muted" >About Us</a></li>
      <li class="nav-item foot-item footItem"><a href="/findus" class="nav-link-1 px-2 text-body-muted">Find Us!</a></li>
    </ul>
  </footer>
</div>


</template>
<style>
.container {
  text-align: center;
}
.text {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
.nav-link-1 {
  color: rgb(75, 75, 75) !important;
  font-size: 1.25em !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif!important;
  text-decoration: none;
}

.foot-item{
  border: 1px solid black;
  width: 160px;
  margin-left: 10px;
  margin-top: 10px;
  opacity: 0.5;
  transition: 0.3s;
  border-radius: 40px;
}

.foot-item:hover{
  background-color: rgb(99, 99, 99);
}
@media screen and (max-width: 1200px) {
  .foot-item {
  margin-top: 10px; /* Add margin to create space between links */
}
  
}

@media screen and (max-width: 545px) {
  .foot-item{
    width: 200px;
  }
  
}


@media screen and (max-width: 500px) {
  .footNav{
    margin-left: 25%;
  }
  
}


@media screen and (max-width: 400px) {
  .footNav{
    margin-left: 10%;
  }
  
}



</style>